import convert from 'convert-units-forked'
import { get, set, cloneDeep, uniq, intersection } from 'lodash'
import { urlPathController } from '@/fleet-configuration/page-components/wizard/wizard-navigation/wizard-navigation-constants'

export const parseKistlerUnit = unit => {
  // conversion from kistler notation to the notation used by convert-units library
  const kistlerUnitMap = {
    C: 'c',
    '°C': 'C',
    '°F': 'F',
    '°K': 'K',
    '(m/s²)': 'm/s2',
    'm/s²': 'm/s2',
    'm*s^-2': 'm/s2',
    gforce: 'g-force',
    g: 'g-force',
    Ω: 'Ohm',
    kΩ: 'kOhm',
    MΩ: 'MOhm',
    mΩ: 'mOhm',
    με: 'ue',
    'lbf-in': 'lb-in',
    'lbf-ft': 'lb-ft',
    'g-mass': 'g',
    RPM: 'rpm',
    µs: 'mu',
    t: 'mt',
    // following are ratios not units - but we can get those by abusing underlying units from them
    'µV/V': 'µV',
    'mV/V': 'mV',
    'V/V': 'V',
    'µm/m': 'µm',
    'mm/m': 'mm',
    'm/m': 'm',
    // rewrite visually similar characters to actual used character
    μV: 'µV',
    μA: 'µA',
    μC: 'µC',
    μm: 'µm',
  }
  return kistlerUnitMap[unit] || unit
}

export const convertUnit = (sourceUnit, targetUnit, value) => {
  if (sourceUnit === targetUnit) {
    return value
  }

  return convert(value).from(parseKistlerUnit(sourceUnit)).to(parseKistlerUnit(targetUnit))
}

export const parseField = (type, value) => {
  if (type instanceof Array) {
    return value instanceof Array ? value.map(parseField.bind(null, type[1])) : parseField(type[1], value)
  }
  if (['double', 'integer'].includes(type)) {
    return parseFloat(value.toString())
  }
  return value
}

export const getFieldName = prefixedName => prefixedName.split(':').pop() // handle if field is not prefixed

export const getDependentOptions = (catalog, channel, channelOptions, field) => {
  const dependencies = catalog.getDependencies(field)
  return dependencies.reduce((accOptions, dependency) => {
    let dependentField = getFieldName(dependency)
    const isThermocouple = [
      'GrpTC/Pa0CoInt/Pa1No/Pa2No/Pa3No',
      'GrpTC/Pa0CoExt/Pa1No/Pa2No/Pa3No',
      'GrpRe/Pa02Wi/Pa1No/Pa2No/Pa3No',
      'GrpRe/Pa04Wi/Pa1No/Pa2No/Pa3No',
    ].includes(channel.parameters.type)
    if (field === 'hwRange' && dependentField === 'type' && isThermocouple) {
      dependentField = 'sensorType'
    }
    const dependentValue = channel.parameters[dependentField]
    if (dependentValue !== undefined && channel.hasField(dependentField)) {
      const option = channelOptions.getOption(dependentField, dependentValue)
      accOptions.push(option)
      return accOptions
    }
    return accOptions
  }, [])
}

export const getSensitivityUnitPair = (options, channel) => {
  if (!channel.parameters.sensitivityUnit) {
    return { sensitivitySensorUnit: '', sensitivityPhysicalUnit: '' }
  }
  let sensitivityUnitOption = options.getOption('sensitivityUnit', channel.parameters.sensitivityUnit)

  if (sensitivityUnitOption.unknown) {
    const sensitivityUnitOptions = options.getEnumOptions('sensitivityUnit')
    // eslint-disable-next-line prefer-destructuring
    sensitivityUnitOption = sensitivityUnitOptions[0]
    if (sensitivityUnitOption) {
      console.error(
        `${channel.parameters.sensitivityUnit} not found in sensitivityUnit options ${JSON.stringify(
          sensitivityUnitOptions,
        )}. first option ${sensitivityUnitOption.value} used instead`,
      )
    } else {
      console.error("Can't fetch sensitivity unit option")
      return { sensitivitySensorUnit: null, sensitivityPhysicalUnit: null }
    }
  }

  const { sensorUnitOption, physicalUnitOption } = sensitivityUnitOption
  return {
    sensitivitySensorUnit: (sensorUnitOption || {}).value,
    sensitivityPhysicalUnit: (physicalUnitOption || {}).value,
  }
}

export const mirrorDataFromSpecificSourceToParameters = (channel, catalog) => {
  const mirrorFields = catalog.getMirrorResourcesFields()
  mirrorFields.forEach(field => {
    const option = catalog.getEnumOption(field, channel.parameters[field])
    if (option && option.mirrorResources) {
      const toObj = get(channel, option.mirrorResources.toPath)
      if (toObj) {
        option.mirrorResources.map.forEach(({ fromField, toField }) => {
          const sourceValue = toObj[toField]
          // physicalQuantity is required property that is just not in BE.
          // We need to compute her out of "thin air" (basically guess it). So this is it...
          if (
            toField === 'physicalUnit' &&
            channel.parameters[fromField] !== sourceValue &&
            catalog.hasField('physicalQuantity')
          ) {
            const unitOptions = catalog.getEnum('physicalUnit').filter(unit => unit.value === sourceValue)
            const unitOptionGroups = unitOptions.map(unitOption => unitOption.group)
            const quantityPossibilities = catalog.getEnum('physicalQuantity')
            const guessedQuantity = quantityPossibilities.find(quantityCandidate =>
              quantityCandidate.filters?.some(filterProps => {
                if (getFieldName(filterProps.field) === toField) {
                  return intersection(filterProps.values?.groups || [], unitOptionGroups).length
                }
                return false
              }),
            )
            if (guessedQuantity) {
              // eslint-disable-next-line no-param-reassign
              channel.parameters.physicalQuantity = guessedQuantity.value
            } else {
              // eslint-disable-next-line no-param-reassign
              channel.parameters.physicalQuantity = channel.isLabAmp() ? 'Custom Unit' : 'Custom value'
              // eslint-disable-next-line no-param-reassign
              channel.parameters.customUnit = true
            }
          }
          // NOTE: clonedeep is not oversight. sourceValue can be array (and there were problems)
          // eslint-disable-next-line no-param-reassign
          channel.parameters[fromField] = cloneDeep(sourceValue)
        })
      }
      ;(option.mirrorResources.remove || []).forEach(removeField => {
        // eslint-disable-next-line no-param-reassign
        delete channel.parameters[removeField]
      })
    }
  })
  return channel
}

let fieldsToMirror = []
const canMirror = channelToUpdate =>
  !channelToUpdate.validate().some(fieldValidationResult => fieldValidationResult.alerts.length)

export const executePreparedMirroring = (mirrorChannel, catalog) => {
  const actualFieldsToMirror = uniq(fieldsToMirror)
  const mirrorFields = catalog.getMirrorResourcesFields()
  if (!mirrorFields.length) {
    return mirrorChannel
  }
  // if we changed one of the parameters that swaps subtree, then execute swapping immediately
  if (intersection(actualFieldsToMirror, mirrorFields).length) {
    fieldsToMirror = []
    // we don't need to check other mirroring now, because we already mirrored in opposite direction
    // that means that we would write back same stuff that we just copied from that source
    return mirrorDataFromSpecificSourceToParameters(mirrorChannel, catalog)
  }
  // do not mirror fields if the channel is (or would be) invalid
  if (!canMirror(mirrorChannel)) {
    return mirrorChannel
  }
  fieldsToMirror = []
  // for mirror field's option can be fetch once like this, because mirror fields are not changed
  // mirrored (taken in) properties. Said in a different way - option.mirrorResources.map's
  // properties never contain any property from mirrorFields.
  // If this assumption will be broken, you would need to fetch these options on every place this
  // map is used
  const mirrorFieldsToOptionMap = mirrorFields.reduce((acc, mirrorField) => {
    acc[mirrorField] = catalog.getEnumOption(mirrorField, mirrorChannel.parameters[mirrorField])
    return acc
  }, {})

  actualFieldsToMirror.forEach(field => {
    const nonIndexField = field.replace(/\[[^\]]+\]/g, '') // remove "[ANYTHING]" stuff
    mirrorFields.forEach(mirrorField => {
      const option = mirrorFieldsToOptionMap[mirrorField]
      if (option.mirrorResources) {
        const mirrorMapOption = option.mirrorResources.map.find(
          ({ fromField }) => fromField === field || fromField === nonIndexField,
        )
        if (mirrorMapOption) {
          // NOTE: parseField creates new copy of arrays - hence no need to cloneDeep this. Should
          // we change it's implementation, or stop using parseField in here -> return cloneDeep
          const valueToWrite = parseField(catalog.getType(nonIndexField), mirrorChannel.parameters[nonIndexField])
          set(mirrorChannel, `${option.mirrorResources.toPath}.${mirrorMapOption.toField}`, valueToWrite)
        }
      }
    })
  })
  return mirrorChannel
}
export const prepareMirroringDataFromParametersToSpecificSource = (channelToUpdate, catalog, field) => {
  fieldsToMirror.push(field)
  return channelToUpdate
}

export const hasCustomUnit = channel => ['Custom value', 'Custom Unit'].includes(channel.parameters.physicalQuantity)

export const getUnitFactorAndOffset = (options, unit) => {
  const unitOption = options.getOption('unit', unit)
  if (!unitOption.changes) {
    return {}
  }
  const unitFactor = unitOption.changes.find(c => getFieldName(c.field) === 'unitFactor')
  const unitOffset = unitOption.changes.find(c => getFieldName(c.field) === 'unitOffset')
  return { unitFactor: unitFactor.values.value, unitOffset: unitOffset.values.value }
}

export const getSensitivitySensorUnitFactor = (options, channel) => {
  if (!channel.hasField('unit')) {
    return 1
  }
  const { sensitivitySensorUnit } = getSensitivityUnitPair(options, channel)
  const { unitFactor } = getUnitFactorAndOffset(options, sensitivitySensorUnit)
  return unitFactor || 1
}

// this method always rounds toward 0. Precision 12 is largest possible number that will always work
export const toPreciseNumber = (sourceValue, precision = 12) => {
  // special case that is problem to compute
  if (sourceValue === 0) {
    return 0
  }
  // first get how many digits are before first significant digit
  // to explain: 123.45 to precision 4 is 123.4 - multiply it by 10 instead of 10000 before flooring
  const integralDigits = Math.floor(Math.log10(Math.abs(sourceValue))) + 1
  const ratio = 10 ** (precision - integralDigits)
  // first round by 3 more digits so as to not run into number precision limits
  return Math[sourceValue > 0 ? 'floor' : 'ceil'](Math.round(sourceValue * ratio * 1000) / 1000) / ratio
}

export const getZeroOffsetToBaseUnit = (channel, zeroOffsetValue) => {
  const options = channel.getChannelOptions()
  const baseUnit = options.getBaseUnit()
  const { sensitivitySensorUnit } = getSensitivityUnitPair(options, channel)
  if (baseUnit && sensitivitySensorUnit) {
    return convertUnit(baseUnit, sensitivitySensorUnit, zeroOffsetValue)
  }
  return zeroOffsetValue
}

export const rangeFromBaseToPhysicalUnit = (channel, range) => {
  // Recalculates physical range (i.e. applies sensitivity & offset) from the channel base unit
  // into the selected physical unit (e.g. V -> mA).
  const options = channel.getChannelOptions()
  const baseUnit = options.getBaseUnit()
  const { sensitivitySensorUnit, sensitivityPhysicalUnit } = getSensitivityUnitPair(options, channel)
  const { physicalUnit, sensitivity, pulseFactor } = channel.parameters
  let ratio
  if (channel.hasField('sensitivity')) {
    ratio = sensitivity
  } else if (channel.hasField('pulseFactor')) {
    ratio = pulseFactor
  } else {
    ratio = 1
  }
  if (channel.isLabAmp()) {
    ratio = Math.abs(ratio)
  }

  if (!sensitivitySensorUnit) {
    return range
  }

  const offsetAdjustedRange = range - convertUnit(sensitivitySensorUnit, baseUnit, channel.getZeroOffset())
  const rangeInSensorSensitivityUnit = convertUnit(baseUnit, sensitivitySensorUnit, offsetAdjustedRange)
  const rangeInPhysicalSensitivityUnit = rangeInSensorSensitivityUnit / ratio

  if (!physicalUnit) {
    return rangeInPhysicalSensitivityUnit
  }
  let rangeInPhysicalUnit
  try {
    rangeInPhysicalUnit = convertUnit(sensitivityPhysicalUnit, physicalUnit, rangeInPhysicalSensitivityUnit)
  } catch (err) {
    if (channel.hasCustomUnit()) {
      console.debug(
        `Conversion between custom unit "${sensitivityPhysicalUnit}" and physical unit "${physicalUnit}" was not possible`,
      )
    } else {
      console.error('Incompatible sensitivity unit with physical unit', sensitivityPhysicalUnit, physicalUnit, err)
    }
    rangeInPhysicalUnit = rangeInPhysicalSensitivityUnit
  }
  return toPreciseNumber(rangeInPhysicalUnit)
}

export const rangeFromPhysicalToBaseUnit = (channel, range) => {
  // Recalculates physical range (i.e. applies sensitivity & offset) from the selected physical unit
  // into the base unit of the channel (e.g. mA -> V).
  const options = channel.getChannelOptions()
  const { physicalUnit, sensitivity, pulseFactor } = channel.parameters
  const ratio = sensitivity || pulseFactor
  const { sensitivitySensorUnit, sensitivityPhysicalUnit } = getSensitivityUnitPair(options, channel)
  const rangeInPhysicalSensitivityUnit = convertUnit(physicalUnit, sensitivityPhysicalUnit, range)
  const rangeInSensorSensitivityUnit = rangeInPhysicalSensitivityUnit * Math.abs(ratio)
  const rangeWithOffsetCompensation = rangeInSensorSensitivityUnit + channel.getZeroOffset()
  const baseUnit = options.getBaseUnit()

  if (!sensitivitySensorUnit || !baseUnit) {
    return toPreciseNumber(rangeWithOffsetCompensation)
  }

  const rangeInBaseUnit = convertUnit(sensitivitySensorUnit, baseUnit, rangeWithOffsetCompensation)
  return toPreciseNumber(rangeInBaseUnit)
}

export const pathSegmentsFactory = (moduleId, channelId) => {
  const pathSegments = []

  if (moduleId !== undefined) {
    if (moduleId === urlPathController) {
      pathSegments.push(moduleId)
    } else {
      pathSegments.push('modules', moduleId)
    }
  }

  if (channelId !== undefined) {
    pathSegments.push('channels', channelId)
  }
  return pathSegments
}
